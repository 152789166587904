<template>
    <div class="goods-shoot-content">
        <div>
            <div class="goods-shoot-header">
                <span>素材下载</span>
                <el-button type="primary" @click="clickDownload" style="margin-bottom: 16px">点击下载</el-button>
            </div>
            <div class="first-title">
                <span>图片</span>
            </div>
            <el-form :model="goodDetail" :rules="formRules" ref="goodDetail" label-width="100px" class="demo-ruleForm">
                <el-form-item label="商品图片" prop="good_images" class="goods-images-box column-layout">
                    <div class="flex">
                        <div class="good-image-item" v-for="imageIndex in 8">
                            <i v-if="goodDetail.good_images[imageIndex - 1]"
                               @click="clearImg(imageIndex)"
                               class="el-icon-error clear-quickTalk-img"></i>
                            <el-upload class="img-uploader"
                                       :action="uploadCover"
                                       :headers="headersParams"
                                       name="img"
                                       accept=".jpg, .jpeg, .png"
                                       :show-file-list="false"
                                       :on-success="function(res, file) {return handleCoverSuccess(res, file, imageIndex)}"
                                       :before-upload="beforeCoverUpload">
                                <img v-if="goodDetail.good_images[imageIndex - 1]" :src="goodDetail.good_images[imageIndex - 1]" class="quickTalk-img">
                                <div class="add-box" v-else>
                                    <i class="iconfont add-icon">&#xe817;</i>
                                    <div class="add-text">{{imageIndex === 1 ? '主图' : '细节图'}}</div>
                                </div>
                            </el-upload>
                        </div>
                    </div>
                    <div style="color: #999; margin-top: 12px;">图片至少上传5张，尺寸为800px*800px，仅支持jpg、jpeg、png格式</div>
                </el-form-item>
            </el-form>
        </div>
        <div  style="text-align: center">
            <el-button type="primary" @click="saveGoodsShoot">提交</el-button>
        </div>
    </div>
</template>

<script>
    import {collectSave_img,stuOpGetExamInfo, collectImg} from '@/utils/apis.js'
    export default {
        name: "GoodsShoot",
        data() {
            let checkImgLength = (rule, value, callback) => {
                if (value.length === 0) {
                    return callback(new Error('请选择图片'));
                }
                let arrTmp = value.filter(item => {
                    return item
                })
                if (arrTmp.length < 5) {
                    return callback(new Error('商品图片不得少于5张'));
                } else {
                    callback()
                }
            }
            return {
                goodDetail: {
                    id: Number(this.$route.query.id) || null,
                    category_id: Number(this.$route.query.category_id) || null,
                    good_title: '',
                    sales_price: void 0,
                    market_price: void 0,
                    stock: void 0,
                    good_no: '',
                    good_property: [],
                    good_images: [],
                    good_details: '',
                },
                formRules: {
                    good_title: [
                        { required: true, message: '请输入商品标题', trigger: 'blur' },
                        { min: 2, max: 50, message: '长度在 2 到 50 个字符', trigger: 'blur' }
                    ],
                    sales_price: { required: true, message: '请输入销售价', trigger: 'blur' },
                    market_price: { required: true, message: '请输入市场价', trigger: 'blur' },
                    stock: { required: true, message: '请输入库存', trigger: 'blur' },
                    good_no: { required: true, message: '请输入商品货号', trigger: 'blur' },
                    good_property: { required: true, message: '请添加商品属性', trigger: 'blur' },
                    good_images: { validator: checkImgLength },
                    good_details: { required: true, message: '请输入电脑版商品详情', trigger: 'blur' },
                },
                headersParams: {
                    Authorization: localStorage.getItem('studentToken')
                },
                uploadCover: 'collect/up_img',
                //提交id
                id: null,
                //要下载的素材
                downloadMaterial: ''
            }
        },
        created() {
            this.getGoodsImg();
            this.getDownloadMaterialGoodsImg();
        },
        methods: {
            //获取学生商品主图答题内容
            getGoodsImg() {
                let param = {}
                if (this.$route.query.id) {
                    param.op_id = this.$route.query.id;
                    param.course_id = this.$route.query.course_id;
                }
                collectImg(param).then((res) => {
                    if (res.data.id) {
                        this.id = res.data.id;
                        this.goodDetail.good_images = res.data.file_path;
                    }
                }).catch((err) => {
                    console.log(err);
                })
            },
            //获取下载素材
            getDownloadMaterialGoodsImg() {
                let p = {
                    type: "goods_info_collect"
                }
                if (this.$route.query.id) {
                    p.op_id = this.$route.query.id;
                }
                stuOpGetExamInfo(p).then((res) => {
                    this.downloadMaterial = res.data.file_download;
                }).catch((err) => {
                    console.log(err);
                });
            },
            //点击下载
            clickDownload() {
                window.location.href = this.downloadMaterial;
            },
            // 图片上传前
            beforeCoverUpload(file) {
                const isSize = new Promise((resolve, reject) => {
                    const width = 800;
                    const height = 800;
                    const _URL = window.URL || window.webkitURL;
                    const img = new Image();
                    img.onload = () => {
                        const valid = img.width === width && img.height === height;
                        valid ? resolve() : reject();
                    };
                    img.src = _URL.createObjectURL(file);
                }).then(() => {
                        return file;
                    }, () => {
                        this.$message.warning('图片尺寸限制为800px x 800px，大小不可超过10MB');
                        return Promise.reject();
                    },
                );
                return isSize;
            },
            // 图片上传成功后
            handleCoverSuccess(res, file, index) {
                if (res.code === 200) {
                    this.$set(this.goodDetail.good_images, index - 1, res.data.src)
                    this.$message.success(res.msg)
                } else {
                    this.$message.error('图片上传失败，请稍后再试~')
                }
            },
            // 删除图片
            clearImg(index) {
                this.goodDetail.good_images.splice(index - 1, 1)
            },
            //提交
            saveGoodsShoot() {
                if(this.goodDetail.good_images.length < 5){
                    this.$message.warning('图片至少上传5张')
                    return
                }
                let param = {
                    file_path: this.goodDetail.good_images
                }
                if (this.$route.query.id) {
                    param.course_id = Number(this.$route.query.course_id);
                    param.op_id = Number(this.$route.query.id);
                }
                if (this.id) {
                    param.id = this.id;
                }
                collectSave_img(param).then((res) => {
                    this.$message.success(res.msg);
                }).catch((err) => {
                    console.log(err);
                })
            }
        }
    }
</script>

<style scoped lang="scss">
    .goods-shoot-content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        padding: 20px;
        box-sizing: border-box;
        .goods-shoot-header {
            display: flex;
            justify-content: space-between;
            /*align-items: center;*/
            /*height: 58px;*/
            border-bottom: 2px solid #F1F5FF;
            span {
                color: #333;
                font-size: 16px;
            }
        }
    }
    .first-title {
        font-size: 20px;
        padding-left: 15px;
        line-height: 1;
        margin-top: 20px;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        &:before {
            content: '';
            position: absolute;
            top: 50%;
            left: 0;
            width: 5px;
            height: 20px;
            background: #2DC079;
            border-radius: 3px;
            transform: translateY(-50%);
        }
    }
    .goods-images-box {
        background: #F6F6F6;
        margin-top: 14px;
        padding: 20px 15px;
    }
    .column-layout {
        display: flex;
        flex-direction: column;
        ::v-deep {
            .el-form-item__label {
                text-align: left;
                line-height: 1;
                margin-bottom: 20px;
                width: auto!important;
            }
            .el-form-item__content {
                margin-left: 0!important;
            }
        }
    }
    .good-image-item + .good-image-item {
        margin-left: 10px;
    }
    .good-image-item {
        width: 100px;
        height: 100px;
        position: relative;
        background: #fff;
        .clear-quickTalk-img {
            background: #fff;
            border-radius: 50%;
            position: absolute;
            top: -9px;
            right: -9px;
            z-index: 1;
            font-size: 18px;
            color: #aaa;
            cursor: pointer;
            transition: all .3s;
            &:hover {
                color: #ff0000;
            }
        }
        .img-uploader {
            position: absolute;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;
            .quickTalk-img {
                display: block;
                max-width: 100%;
                max-height: 100%;
            }
            .add-box {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                line-height: 1;
                display: flex;
                flex-direction: column;
                justify-content: center;
                .add-icon {
                    color: #BFBFBF;
                    font-size: 24px;
                }
                .add-text {
                    color: #999;
                    font-size: 14px;
                    margin-top: 8px;
                }
            }
        }
    }
</style>